@import url("../../assets/css/variable.css");
header{
    background-color: var(--blue);
}
header svg{
    stroke: #fff;
}
.header2{
    background-image: url("https://jthemes.net/themes/wp/pergo/demos/wp-content/themes/pergo/images/slider/slide-1.jpg");
    background-size: cover;
    height: 800px;
}
.header2 header{
    background-color: rgba(48, 56, 72, 0.8);
}
.header2 h1{
    font-size: calc(5vw + 1rem);
}
.header2 h3{
    font-size: calc(1vw + 1rem);
}
.navbar {
    --bs-navbar-nav-link-padding-x: 0.7rem !important;
    --bs-nav-link-font-size:19px !important
}
.navbar .menu{
    --bs-navbar-nav-link-padding-x: 2rem !important;
}
.filter-white{
    filter: brightness(0) invert(1);
}
@media (max-width: 786px) {
    .header2{
        height: 550px;
        background-position: left !important;
    }
    .navbar {
        --bs-navbar-nav-link-padding-x: 0.5rem !important;
        --bs-nav-link-font-size:17px !important
    }

    @media only screen and (min-width: 750px) {
        h1, .h1 {
            font-size: 4rem;
            font-weight: bold !important;
        }
    }

}
