.form-control{
    border-radius: 0px !important;
    border:1px solid var(--black) !important;
    min-height: 45px ;
    margin-bottom: 20px;
}
.btn-dark{
    border-radius: 0px !important;
    border:1px solid var(--black) !important;
    background-color: var(--black) !important;
    min-width: 200px;
    padding:10px 20px !important;
}
.container-lg{
    max-width: 1100px !important;
}
a{
    text-decoration: none;
}
a p{
    color: #000000;
    font-size: 18px;
}
@media screen and (min-width: 750px) {
    .title-page {
        font-size: 3rem;
        margin-bottom: 2rem;
        word-break: break-word;
    }
    a p{
        font-size: 18px;
    }
}

@media screen and (max-width: 751px) {
    a p{
        font-size: 16px;
    }
}
.quantity {
    position: relative;
    width: 170px;
    display: flex;
    border-radius: 0;
    min-height: 3rem;
}
.quantity__input,
.quantity__input:focus,
.quantity__input:hover{
    color: currentColor;
    font-weight: 500;
    opacity: .85;
    text-align: center;
    background-color: transparent;
    border: 0 !important;
    padding: 0 .5rem;
    width: 100%;
    flex-grow: 1;
    -webkit-appearance: none !important;
    appearance: none !important;
    box-shadow: none !important;
}
.quantity__button {
    width: 3rem;
    flex-shrink: 0;
    font-size: 1.8rem;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

ul li{
    margin-bottom: 10px;
}
