html,body{
    position: relative;
    height: 100%;
}
body{
    font-family: "Assistant", sans-serif !important;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}
